import BackgroundPattern from "@components/BackgroundPattern";
import { ITheme } from "@lib/themes/types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@lib/themes";
import Head from "next/head";
import NextLink from "next/link";
import { Search } from "@components/app/search";
import cx from "classnames";
import { useAuth } from "@components/app";

const useStyles = makeStyles((theme: ITheme) => ({
  lableContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  label1: {
    fontSize: 288,
    lineHeight: 1.15,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 200,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 160,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 100,
    },
  },
  label2: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 330,
    },
  },
  subTitle: {
    fontSize: 20,
  },
  subTitleTop: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  homeLink: {
    marginLeft: 4,
    "& a": {
      color: "rgb(3, 169, 206)",
      textDecoration: "none",
      fontSize: 20,
    },
  },
  searchBox: {
    padding: "0px 8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const Page404 = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  return (
    <div>
      <Head>
        <title>Page Not Found</title>
      </Head>

      <BackgroundPattern forErrorPage />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={classes.lableContainer}>
        <Typography
          variant="h3"
          color="textPrimary"
          mb={2}
          align="center"
          className={classes.title}>
          Sorry, We really searched it well, but we couldn't find it.
        </Typography>
        <Box
          className={cx(classes.subTitle, classes.subTitleTop)}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          mb={2}>
          Maybe you want to try
          {isAuthenticated ? (
            <Box className={classes.searchBox}>
              <Search maxHeight="30vh" />
            </Box>
          ) : (
            " search "
          )}
          for something else?
        </Box>
        <Box display="flex">
          <Typography className={classes.subTitle}>
            If not, Lets go back
          </Typography>
          <Box className={classes.homeLink}>
            <NextLink href={"/"} key={`/`}>
              home
            </NextLink>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Page404;
